// import styles from './DefaultLayout.module.scss';
import './layout.css';
// const cx = classNames.bind(styles);

function DefaultLayoutRegister({ children }) {
    document.title = children.props.title;
    return <div className="bg-[#ea943f] min-h-[100vh]">{children}</div>;
}

export default DefaultLayoutRegister;
