import { useState, useEffect } from 'react';
import { GrSystem } from 'react-icons/gr';
import { useTranslation } from 'react-i18next';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
const axios = require('axios').default;

export function Notification() {
    const [user, setUser] = useState();
    const { t } = useTranslation();
    useEffect(() => {
        // checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;

                if (data.status === 'ok') {
                    setUser(data);
                    // setVipLevel(data.data[0].name_level);
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }, []);

    console.log(user);
    return (
        <div className="bg-[#f0f3f7] p-4 rounded-3xl ">
            <div className="bg-white min-h-[15rem] rounded-3xl p-6">
                <div className="flex fs-3 font-bold text-[#00b4ff] items-center">
                    <GrSystem />
                    <span className="ml-2">Tin nhắn hệ thống</span>
                </div>
                <div className="flex text-[1.3rem] items-center">
                    <p className="mr-2">Nội dung:</p>
                    <p>Tạo tài khoản thành công</p>
                </div>
                <div className="flex text-[1.3rem]">
                    <span className="p-2 mr-2">Chi tiết:</span>
                    <div className="bg-[#f0f3f7] px-6 py-2 rounded-2xl flex flex-col">
                        <span>{`Tên đăng nhập: ${user?.data[0].username} `}</span>
                        <span>{`Mã mời: ${user?.data[0].id_user} `}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
