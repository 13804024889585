import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-toastify/dist/ReactToastify.css';
// import { banner1, banner2, banner3 } from '../../../images';
import axios from 'axios';
import SETTINGS from '../../../setting.json';
// import Loading from '../../../../components/common/loading';
import { headers } from '../../../utils/consts';
import { toast } from 'react-toastify';
// const domain = process.env.REACT_APP_DOMAIN;
import banner1 from '../../../assets/images/banner_kokomart/banner1.webp';
import banner2 from '../../../assets/images/banner_kokomart/banner2.webp';
import banner3 from '../../../assets/images/banner_kokomart/banner3.webp';
import banner4 from '../../../assets/images/banner_kokomart/banner4.webp';

function Banner() {
    // const [banners, setBanner] = useState([]);
    // const [loading, setLoading] = useState(false);
    const banners = [banner1, banner2, banner3, banner4];
    // useEffect(() => {
    //     // setLoading(true);
    //     axios
    //         .get(`${SETTINGS.BASE_URL}/api/webapi/list/banners`, {
    //             headers,
    //         })
    //         .then(function (response) {
    //             // setLoading(false);
    //             // console.log(response.data);
    //             let status = response.data.status;
    //             if (status === true) {
    //                 setBanner(response.data.data);
    //             }
    //             return toast.error(response.data.msg);
    //         })
    //         .catch(function (error) {
    //             // setLoading(false);
    //             toast.error('Lỗi server');
    //         });
    // }, []);

    return (
        <>
            <Carousel
                autoPlay={true}
                showArrows={false}
                showStatus={false}
                dynamicHeight={true}
                emulateTouch={true}
                infiniteLoop={true}
                interval={2000}
                showIndicators={true}
                showThumbs={false}
                swipeable={true}
                // className="slider_style"
            >
                {banners.map((item, i) => {
                    return (
                        <div key={i} className="bg-blue-gray-200 rounded-3xl">
                            <img src={item} alt="banner" className="rounded-3xl w-full h-full" />
                        </div>
                    );
                })}
            </Carousel>
        </>
    );
}

export default Banner;
