export const headers = {
    'x-access-token': localStorage.getItem('auth_portal'),
    'Access-Control-Allow-Origin': '*',
};

export const list_money_recharge = [20000, 50000, 100000, 200000, 300000, 50000, 1000000, 2000000, 5000000, 10000000];

export const roses_user = {
    vip0: 0,
    vip1: 1,
    vip2: 1,
    vip3: 1,
    vip4: 1.5,
    vip5: 2,
    vip6: 2.5,
    vip7: 3,
};
