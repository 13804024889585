import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import './History.css';
import imageEmail from '../../assets/images/image-email.png';
import { useTranslation } from 'react-i18next';
import { MdLocalOffer } from 'react-icons/md';

const axios = require('axios').default;

const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
});

function formateT(params) {
    let result = params < 10 ? '0' + params : params;
    return result;
}

function timerJoin2(params = '') {
    let date = '';
    if (params) {
        date = new Date(Number(params));
    } else {
        date = new Date();
    }
    let years = formateT(date.getFullYear());
    let months = formateT(date.getMonth() + 1);
    let days = formateT(date.getDate());

    let hours = formateT(date.getHours());
    let minutes = formateT(date.getMinutes());
    let seconds = formateT(date.getSeconds());
    return years + '-' + months + '-' + days + ' ' + hours + ':' + minutes + ':' + seconds;
}

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

function HistoryRechargeWithdraw({ title }) {
    const [activeTab, setActiveTab] = useState('1');
    const [activeTabPane, setActiveTabPane] = useState('1');

    document.title = title;

    let [recharge, setRecharge] = useState([]);
    let [withdraw, setWithdraw] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/history-recharge`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setRecharge(data.data.recharge);
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }, []);

    useEffect(() => {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/history-withdraw`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setWithdraw(data.data.withdraw);
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }, []);

    if (!Array.isArray(recharge)) return false;

    return (
        <div
            className="history-order main-container bg-[#ffffff] overflow-x-hidden flex flex-col items-center justify-center pb-[70px] mt-16"
            style={{ borderRadius: '25px' }}
        >
            <Nav tabs className="history-body tours-item w-[90%]" style={{ borderTop: 'none' }}>
                <NavItem
                    className={activeTab === '1' ? 'active w-[50%]' : 'w-[50%]'}
                    onClick={() => {
                        setActiveTabPane('1');
                        setActiveTab('1');
                    }}
                >
                    {t('content.historyRecharge.napTien')}
                </NavItem>
                <NavItem
                    className={activeTab === '2' ? 'active w-[50%]' : 'w-[50%]'}
                    onClick={() => {
                        setActiveTabPane('2');
                        setActiveTab('2');
                    }}
                >
                    {t('content.historyRecharge.rutTien')}
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTabPane}>
                <TabPane tabId="1">
                    <div className="mx-2 flex flex-col items-center justify-center mb-12">
                        {recharge &&
                            recharge.map((item, i) => {
                                return (
                                    <div
                                        className="flex bg-[#d4edda] border-[#c3e6cb] text-[#155724] rounded-3xl mt-4 p-4 mx-4 w-[100%]"
                                        key={i}
                                    >
                                        <div className="w-[40px] h-[40px] flex items-center justify-center bg-[white] rounded-full">
                                            <MdLocalOffer size={24} color="#e46937" />
                                        </div>
                                        <div className="ml-4 flex flex-col items-start justify-center text-2xl w-[80%] text-[#777777]">
                                            <span className="font-bold">
                                                {item.status == '2' && (
                                                    <span className="badge_style badge-fail">
                                                        {t('content.historyRecharge.thatBai')}
                                                    </span>
                                                )}
                                                {item.status == '1' && (
                                                    <span className="badge_style badge-success">
                                                        {t('content.historyRecharge.thanhCong')}
                                                    </span>
                                                )}
                                                {item.status == '0' && (
                                                    <span className="badge_style badge-pending">
                                                        {t('content.historyRecharge.choXuLy')}
                                                    </span>
                                                )}
                                            </span>
                                            <span className="">
                                                {t('content.historyRecharge.soLe')}:{' '}
                                                <span className="font-bold">{item.id_txn}</span>
                                            </span>
                                            <span>
                                                {t('content.historyRecharge.soTien')}:{' '}
                                                <span className="font-bold">{formatter.format(item.amount)}</span>
                                            </span>
                                            <span>
                                                {t('content.historyRecharge.thoiGian')}:{' '}
                                                <span className="font-bold">{timerJoin2(item.time)}</span>
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>

                    {!activeTab && (
                        <div className="empty">
                            <div className="empty-image">
                                <img src={imageEmail} alt="Email-Empty" width="120px" height="120px" />
                            </div>
                            <div className="empty-description">{t('content.historyRecharge.chuaCoLS')}</div>
                        </div>
                    )}
                </TabPane>
                <TabPane tabId="2">
                    <div className="mx-2 flex flex-col items-center justify-center mb-12">
                        {withdraw &&
                            withdraw.map((item, i) => {
                                return (
                                    <div
                                        className="flex bg-[#fae3e5] border-[#c3e6cb] text-[#155724] rounded-3xl mt-4 p-4 mx-4 w-[100%]"
                                        key={i}
                                    >
                                        <div className="w-[40px] h-[40px] flex items-center justify-center bg-[white] rounded-full">
                                            <MdLocalOffer size={24} color="#e46937" />
                                        </div>
                                        <div className="ml-4 flex flex-col items-start justify-center text-2xl w-[80%] text-[#777777]">
                                            <span className="font-bold">
                                                {item.status == '2' && (
                                                    <span className="badge_style badge-fail">
                                                        {t('content.historyRecharge.thatBai')}
                                                    </span>
                                                )}
                                                {item.status == '1' && (
                                                    <span className="badge_style badge-success">
                                                        {t('content.historyRecharge.thanhCong')}
                                                    </span>
                                                )}
                                                {item.status == '0' && (
                                                    <span className="badge_style badge-pending">
                                                        {t('content.historyRecharge.choXuLy')}
                                                    </span>
                                                )}
                                            </span>
                                            <span className="">
                                                {t('content.historyRecharge.soLe')}:{' '}
                                                <span className="font-bold">{item.id_txn}</span>
                                            </span>
                                            <span>
                                                {t('content.historyRecharge.soTien')}:{' '}
                                                <span className="font-bold">{formatter.format(item.amount)}</span>
                                            </span>
                                            <span>
                                                {t('content.historyRecharge.thoiGian')}:{' '}
                                                <span className="font-bold">{timerJoin2(item.time)}</span>
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </TabPane>
            </TabContent>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default HistoryRechargeWithdraw;
