import { useEffect, useState } from 'react';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loading from 'react-loading';
const axios = require('axios').default;

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
});

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};
function Withdraw({ title }) {
    document.title = title;
    let navigate = useNavigate();
    let [bank, setBank] = useState();
    let [password, setPassword] = useState();
    let [money, setMoney] = useState();
    let [moneyPending, setMoneyPending] = useState();
    const [checkHandleClick, setCheckHandleClick] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        checkToken();
        setIsLoading(true);
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/user/banking`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                console.log(data);
                setIsLoading(false);
                if (data.data.recharge.length < 1) {
                    window.location.href = '/my/banking';
                } else if (data.data.recharge[0].name_bank === null) {
                    window.location.href = '/my/banking';
                } else if (data.data.recharge.length > 0) {
                    setBank(data.data.recharge);
                    // setMoneyPending(data.data.pending);
                    setMoneyPending(data.data.recharge[0].dongbangtk);
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }, []);

    const upgradeMember = async () => {
        if (!password || !money) return toast.warn(`${t('content.withDraw.thieuThongTin')}`, { theme: 'light' });
        if (money < 100000) return toast.warn(`${t('content.withDraw.minRut')}`, { theme: 'light' });
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/user/withdraw`,
                { password, money },
                {
                    headers,
                },
            )
            .then(async function (response) {
                setCheckHandleClick(true);
                let data = response.data.data;
                if (data) {
                    if (data.type === 1) {
                        // setTimeout(() => {
                        //     navigate('/my/financial-details');
                        // }, 1200);
                        return toast.success(`${t('content.withDraw.taoDonThanhCong')}`, { theme: 'light' });
                    }
                    if (data.type === 2) return toast.error(`${t('content.withDraw.saiMk')}`, { theme: 'light' });
                    if (data.type === 3) return toast.error(`${t('content.withDraw.soDuKDu')}`, { theme: 'light' });
                    if (data.type === 5) return toast.error(data.msg, { theme: 'light' });
                    if (data.type === 4)
                        return toast.warn(`${t('content.withDraw.minRut')} ${formatter.format(data.min)}`, {
                            theme: 'light',
                        });
                    if (data.type === 100) return toast.error(data.msg, { theme: 'light' });

                    // toast.error('Có lỗi xảy ra với server, vui lòng thử lại sau! !', { theme: 'light' });
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    };

    const handleSetMoney = () => {
        setMoney(bank[0].money);
    };

    return (
        <div>
            <div
                className="recharge main-container bg-[] overflow-x-hidden flex flex-col items-center justify-center pb-[70px] mt-16 bg-[#f0f3f7]"
                style={{ borderRadius: '25px' }}
            >
                <div className="p-[15px] rounded-lg flex items-center justify-center flex-col">
                    <div className="py-[10px] text-[15px] text-[green] text-left">
                        {t('content.withDraw.soDuTK')}:
                        <span className="px-[10px]">
                            {isLoading ? (
                                <Loading
                                    type="spinningBubbles"
                                    color="white"
                                    height={'20px'}
                                    width={'20px'}
                                    className="mb-3"
                                />
                            ) : (
                                Array.isArray(bank) && bank.length > 0 && formatter.format(bank[0].money)
                            )}
                        </span>
                    </div>
                    <div className="text-[#000] text-2xl">
                        {t('content.withDraw.soDuDB')}:{' '}
                        {isLoading ? (
                            <Loading
                                type="spinningBubbles"
                                color="white"
                                height={'20px'}
                                width={'20px'}
                                className="mb-3"
                            />
                        ) : (
                            Array.isArray(bank) && bank.length > 0 && formatter.format(moneyPending)
                        )}
                    </div>
                </div>

                <div className="mt-[20px] p-[15px] rounded-3xl bg-[#fff] tours-item w-[90%]">
                    <div className="meun-item">
                        <div className="w-full flex justify-between border-b py-[10px]">
                            <span className="text-[#000] text-[16px]">{t('content.withDraw.sdt')}</span>
                            <span className="text-[16px] text-[#000]">
                                {Array.isArray(bank) &&
                                    bank.length > 0 &&
                                    bank[0].username.slice(0, 3) + '****' + bank[0].username.slice(-3)}
                            </span>
                        </div>
                        <div className="w-full flex justify-between border-b py-[10px]">
                            <span className="text-[#000] text-[16px]">{t('content.withDraw.tkBank')}</span>
                            <span className="text-[16px] text-[#000]">
                                {Array.isArray(bank) &&
                                    bank.length > 0 &&
                                    String(bank[0].stk_bank).slice(0, 7) + '****'}
                            </span>
                        </div>
                        <div className="w-full flex justify-between border-b py-[10px]">
                            <span className="text-[#000] text-[16px]">{t('content.withDraw.tenBank')}</span>
                            <span className="text-[16px] uppercase text-[#000]">
                                {Array.isArray(bank) && bank.length > 0 && String(bank[0].name_bank)}
                            </span>
                        </div>
                        <div className="w-full flex justify-between border-b py-[10px]">
                            <span className="text-[#000] text-[16px]">{t('content.withDraw.ten')}</span>
                            <span className="text-[16px] text-[#000]">
                                {Array.isArray(bank) && bank.length > 0 && String(bank[0].name_u_bank)}
                            </span>
                        </div>
                        <div className="w-full flex justify-between py-[10px] items-start flex-col input-withdraw">
                            <div className="flex items-center justify-between w-full">
                                <span className="text-[#000] text-[16px]">{t('content.withDraw.nhapSoTien')}</span>
                                {isLoading ? (
                                    <Loading
                                        type="spinningBubbles"
                                        color="white"
                                        height={'20px'}
                                        width={'20px'}
                                        className="mb-3"
                                    />
                                ) : (
                                    <span
                                        className="text-[#000] text-[16px] bg-[#2cb300] px-4 py-2 rounded-xl cursor-pointer"
                                        onClick={handleSetMoney}
                                    >
                                        Rút toàn bộ
                                    </span>
                                )}
                            </div>
                            <input
                                onChange={(e) => setMoney(e.target.value)}
                                type="number"
                                className="w-[100%] h-[40px] text-right text-[16px] bg-[#fff] mt-4"
                                placeholder={t('content.withDraw.inputNhapSoTien')}
                                spellCheck="false"
                                autoComplete="off"
                                value={money}
                            />
                        </div>
                        <div className="w-full flex justify-between py-[10px] items-start flex-col input-withdraw">
                            <span className="text-[#000] text-[16px]">{t('content.withDraw.mkVon')}</span>
                            <input
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                className="w-[100%] text-right text-[16px] h-[40px] mt-4"
                                placeholder={t('content.withDraw.inputMkVon')}
                                spellCheck="false"
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </div>

                {checkHandleClick && (
                    <div className="w-[90%]">
                        <button
                            onClick={() => {
                                setCheckHandleClick(false);
                                upgradeMember();
                            }}
                            className="btn-submit"
                        >
                            Rút tiền
                        </button>
                    </div>
                )}

                <div className="mt-[20px] p-[15px] rounded-3xl bg-[#fff] tours-item w-[90%] flex flex-col items-center justify-center text-2xl mb-16">
                    <span className="font-bold">{t('content.withDraw.LUUY')}*</span>
                    <span className="leading-10 text-center mt-4 italic">
                        Thông tin ngân hàng quý khách cần phải liên kết chính xác thì mới có thể rút tiền về ví tín dụng
                        !
                    </span>
                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Withdraw;
