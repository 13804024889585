import classNames from 'classnames/bind';
import styles from './DefaultLayout.module.scss';

const cx = classNames.bind(styles);

function DefaultLayoutTwo({ children }) {
    document.title = children.props.title;
    return (
        <div className={cx('wrapper')}>
            <div className={cx('container-fluid')}>
                <div className="min-h-[100vh] bg-[#ea943f] w-full flex">
                    <div className={cx('content-two')}>{children}</div>
                </div>
            </div>
        </div>
    );
}

export default DefaultLayoutTwo;
