import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './modal.css';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaCheck } from 'react-icons/fa';

export function ModalSuccess({ open, toggle, text }) {
    return (
        <Modal isOpen={open} toggle={toggle} size="sm" centered className="custom-modal">
            <ModalBody>
                <div className="flex items-center justify-center flex-col gap-5 py-[1rem]">
                    <FaCheck color="66bb6a" className={`icon ${open && 'icon-big'}`} />
                    <h3 className="text-[15px] text-[#595959] font-bold">{text}</h3>
                </div>
            </ModalBody>
        </Modal>
    );
}
