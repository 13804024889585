import React from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import { memo } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import backIcon from '../../../../assets/images/backicon.png';
import { IoChevronBackOutline } from 'react-icons/io5';

function HeaderTwo(props) {
    const navigate = useNavigate();

    return (
        <div className="w-full headerDF fixed top-0 z-[49] h-[68px] max-w-[480px]">
            <div className="py-4 flex items-center justify-between w-full px-8">
                <div
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <IoChevronBackOutline size={30} color="white" />
                    {/* <img src={backIcon} alt="" width={20} /> */}
                </div>

                <div className="text-white fs-2 font-semibold">{props.title}</div>
                <div style={{ color: 'transparent' }}>11111</div>
            </div>
        </div>
    );
}

export default memo(HeaderTwo);
