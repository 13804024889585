import React, { useEffect, useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import { memo } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { IoPersonSharp, IoNotifications } from 'react-icons/io5';
import logo from '../../../../assets/images/banner_kokomart/logo.webp';

import logoTraveloka from '../../../../assets/images/logotraveloka.png';
import { FaRegListAlt } from 'react-icons/fa';

function Header(props) {
    return (
        <div className="w-full headerDF fixed top-0 z-[49] h-[68px] max-w-[480px]">
            <div className="py-4 flex items-center justify-between w-full px-8">
                <img src={logo} className="w-[18rem] h-[6rem]" />
                <div className="flex">
                    <Link to="/notification">
                        <IoNotifications className="w-[2rem] h-[2rem] text-white mr-4" />
                    </Link>
                    <Link to="/my">
                        <IoPersonSharp className="w-[2rem] h-[2rem] text-white" />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default memo(Header);
