import { useEffect, useState } from 'react';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { MdLocalOffer } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { list_money_recharge } from '../../utils/consts';

const axios = require('axios').default;

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
});

function Recharge({ title }) {
    document.title = title;
    const [select, setSelect] = useState('bank');
    const [money, setMoney] = useState(0);
    const location = useLocation();

    useEffect(() => {
        if (location.state) {
            if (location.state.missingAmount) {
                setMoney(location.state.missingAmount);
            }
        }
    }, []);

    // const { state } = location.state;
    // console.log(location);

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function RechargeHandler() {
        if (!money || money < 100000) return toast.warn(`${t('content.recharge.minNap')}`, { theme: 'light' });

        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/recharge/add`,
                { money, select },
                {
                    headers,
                },
            )
            .then(function (response) {
                let data = response.data;

                if (data.status === 1) toast.success(data.message, { theme: 'light' });
                if (data.status === 2) toast.warn(data.message, { theme: 'light' });
                // if (data.status) {
                //     setTimeout(() => {
                //         // window.location.href = `/recharge/${data.id_txn}`;
                //         window.open(
                //             '/support',
                //             '_blank', // <- This is what makes it open in a new window.
                //         );
                //     }, 1500);
                // } else {
                //     localStorage.removeItem('auth');
                //     window.location.href = '/account/login';
                // }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }
    return (
        <div>
            <div
                className="recharge main-container bg-[#f0f3f7] overflow-x-hidden flex flex-col items-center justify-center pb-[70px]"
                style={{ borderRadius: '25px' }}
            >
                <div className="flex bg-[#d4edda] border-[#c3e6cb] text-[#155724] rounded-3xl mt-8 p-4 mx-12">
                    <div className="w-[40px] h-[40px] flex items-center justify-center bg-[white] rounded-full">
                        <MdLocalOffer size={24} color="green" />
                    </div>
                    <div className="ml-4 flex flex-col items-start justify-center text-2xl w-[80%]">
                        <span className="font-bold">{t('content.recharge.loiKhuyen')}:</span>
                        <span className="mt-2 leading-10">{t('content.recharge.vuiLong')}!</span>
                    </div>
                </div>
                <div className="flex flex-row flex-wrap w-full px-4 mt-4">
                    {list_money_recharge.map((item, i) => {
                        return (
                            <span
                                key={i}
                                className={`min-w-[6rem] flex-1  m-2  text-center p-2 rounded-lg text-white ${
                                    money == item ? 'bg-[black] ' : 'bg-[#007BFF]  '
                                }  `}
                                onClick={() => setMoney(item)}
                            >
                                {formatter.format(item)}
                            </span>
                        );
                    })}
                </div>
                <div>
                    <div className="text-center my-[25px]">
                        {/* <p className="text-3xl text-[#005652]">{t('content.recharge.soTien')}:</p>
                        <div className="text-[#ffaa03] text-[32px] font-bold">{formatter.format(money) || '0'}</div> */}
                        <div className="form-group px-[15px] mt-[25px]">
                            <p className="text-left py-[5px] text-[14px] font-semibold">Vui lòng nhập số tiền nạp</p>
                            <div className="flex rounded-lg p-[10px]">
                                <input
                                    value={money}
                                    onChange={(e) => setMoney(e.target.value)}
                                    className="w-[100%] text-center p-4 text-[3rem] rounded-lg"
                                    type="number"
                                    placeholder={t('content.recharge.nhapSoTien')}
                                    spellCheck="false"
                                    maxLength="13"
                                    defaultValue={money || location?.state?.missingAmount}
                                />
                            </div>
                        </div>
                        <div className="w-[90%] m-auto">
                            <button onClick={() => RechargeHandler()} className="btn-submit">
                                Gửi đi
                            </button>
                        </div>
                    </div>
                </div>

                <ToastContainer
                    position="top-right"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </div>
    );
}

export default Recharge;
