import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import styles from './Login.module.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import banner from '../../assets/images/banner33.png';
import logoTitle from '../../assets/images/central-logo.png';
import gg_fb from '../../assets/images/logo_gg_fbs.png';
import Account from './index';
import iconUser from '../../assets/images/icon-user.png';
import iconPass from '../../assets/images/icon-pass.png';
import iconShowPass from '../../assets/images/icon-show-pass.png';
import SETTINGS from '../../setting.json';
import Central from '../../assets/images/central.jpg';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import logoTraveloka from '../../assets/images/logotraveloka.png';
import backIcon from '../../assets/images/backicon.png';
import { AiOutlineGlobal } from 'react-icons/ai';
import vietnam from '../../assets/images/vietnam.png';
import england from '../../assets/images/england.png';
import { IoChevronBackOutline, IoPhonePortraitOutline } from 'react-icons/io5';
import { BsFillPhoneFill } from 'react-icons/bs';
import { RiLockPasswordFill, RiLockPasswordLine } from 'react-icons/ri';
import logo from '../../assets/images/banner_kokomart/logo.webp';
import './account.css';
import { ModalSuccess } from '../../components/Modal/modalSuccess';

const axios = require('axios').default;

const cx = classNames.bind(styles);

function Login(props) {
    const [Lock, setLock] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [modalLanguage, setModalLanguage] = useState(false);
    const [open, setOpen] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        document.title = props.title;
    }, [props.title]);

    const checkLock = () => {
        setLock(!Lock);
    };

    const handleLogin = (datas) => {
        let { username, password } = datas;
        let pattern = /^[0-9]*\d$/;
        if (!username || !password) return toast.warn(`${t('content.login.thieuThongTin')}`, { theme: 'light' });
        // if (!pattern.test(username)) return toast.warn(`${t('content.login.saiDinhDang')}`, { theme: 'light' });

        axios
            .post(`${SETTINGS.BASE_URL}/api/webapi/login`, {
                username: username,
                password: password,
            })
            .then(function (response) {
                let status = response.data.status;
                if (status === 'ok') {
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 2000);
                    localStorage.setItem('auth', response.data.auth);
                    toast.success('Kokomart đăng nhập thành công');
                    // setOpen(true);
                    return;
                }
                if (status === 'error' && response.data.message === 'Tài khoản hoặc mật khẩu không chính xác')
                    return toast.error(t('content.login.saiTT'), { theme: 'light' });

                if (status === 'error' && response.data.message === 'Tài khoản đã bị khóa !')
                    return toast.error(t('content.login.tkBiKhoa'), { theme: 'light' });
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    };

    const changeLanguage = (value) => {
        setModalLanguage(false);
        i18n.changeLanguage(value);
        localStorage.setItem('language', value);
    };

    return (
        <div>
            <div className="header-account flex items-center justify-between pt-4">
                {/* <img src={backIcon} alt="" width={20} /> */}
                <IoChevronBackOutline size={30} color="white" />
                <Link className="text-white fs-3 uppercase font-bold" to="/account/register">
                    {t('content.login.taoTKM')}
                </Link>
            </div>
            {/* 
            <div className="float-right mt-2 relative">
                <AiOutlineGlobal size={30} color="#d5582f" onClick={() => setModalLanguage(!modalLanguage)} />

                {modalLanguage && (
                    <div className="absolute top-[40px] right-[0px] flex flex-col items-center justify-center w-[120px] bg-white text-xl rounded-xl mx-2 z-[999]">
                        <div
                            className="flex items-center justify-between px-4 w-[100%] h-[36px]"
                            style={{ borderBottom: '1px solid #d2d2d2' }}
                            onClick={() => changeLanguage('vi')}
                        >
                            <span>Tiếng Việt</span>
                            <img src={vietnam} alt="" width={24} />
                        </div>
                        <div
                            className="flex items-center justify-between px-4 w-[100%] h-[36px]"
                            onClick={() => changeLanguage('en')}
                        >
                            <span>English</span>
                            <img src={england} alt="" width={24} />
                        </div>
                    </div>
                )}
            </div> */}

            <div className={`${cx('side-content')} mt-[130px]`}>
                <div className="img-logo flex items-center justify-center pb-8">
                    <img src={logo} alt="" className={cx('banner-img')} />
                </div>
                <div className={cx('form-group')}>
                    <IoPhonePortraitOutline className={cx('icon-left')} color="#e46937" size={20} />
                    <input
                        className={cx('form-input')}
                        onInput={(e) => {
                            setUsername(e.target.value);
                        }}
                        type="number"
                        name="phoneNumber"
                        placeholder={t('content.login.sdtDN')}
                        autoComplete="off"
                        maxLength="128"
                        spellCheck="false"
                    />
                </div>
                <div className={cx('form-group')}>
                    <RiLockPasswordLine className={cx('icon-left')} color="#e46937" size={20} />
                    <input
                        className={cx('form-input')}
                        onInput={(e) => {
                            setPassword(e.target.value);
                        }}
                        name="password"
                        type={Lock ? 'text' : 'password'}
                        placeholder={t('content.login.matKhauDN')}
                        autoComplete="new-password"
                        maxLength="128"
                    />
                    <img src={iconShowPass} alt="Icon User" className={cx('icon-right')} onClick={checkLock} />
                </div>

                {/* <div className="w-[100%] flex items-center justify-end mb-8 max-w-[280px]">
                    <Link className="text-white fs-4" to="https://t.me/cskhOkela6868" target="_blank">
                        {t('content.login.quenMK')}?
                    </Link>
                </div> */}

                <div>
                    <button
                        type="button"
                        className={cx('block-events')}
                        onClick={() => handleLogin({ username, password })}
                    >
                        {t('content.login.dangNhap')}
                    </button>
                </div>
            </div>

            {/* <Link
                className="absolute text-white top-[95dvh] right-[10px] text-2xl font-bold"
                to="https://t.me/cskhOkela6868"
                target="_blank"
            >
                {t('content.login.lienHeCSKH')}
            </Link> */}

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <ModalSuccess open={open} toggle={() => setOpen(!open)} text={'Đăng nhập thành công'} />
        </div>
    );
}

export default Login;
