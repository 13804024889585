import classNames from 'classnames/bind';
import styles from './Login.module.scss';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
const cx = classNames.bind(styles);

function Account(props) {
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'vi');

    const changeLanguage = (e) => {
        const languageValue = e.target.value;
        setLanguage(languageValue);
        localStorage.setItem('language', languageValue);
        i18n.changeLanguage(languageValue);
    };

    useEffect(() => {
        if (language) i18n.changeLanguage(language);
    }, [language]);

    return (
        <div id="account relative">
            <div className={cx('select-language')}>
                <select name="language" id="language" onChange={changeLanguage} value={language}>
                    <option value="vi">Việt Nam</option>
                    <option value="en">English</option>
                    <option value="jp">日本</option>
                    <option value="kor">한국</option>
                </select>
            </div>
            <main className={cx('container')}>
                <div className={cx('main-content')}>{props.children}</div>
            </main>
        </div>
    );
}

export default Account;
