import { Link } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Home.css';
import { useTranslation } from 'react-i18next';
import { CiLock } from 'react-icons/ci';
import cuchi1 from '../../assets/images/cuchi1.jpg';
import cuchi2 from '../../assets/images/cuchi2.jpg';
import cuchi3 from '../../assets/images/cuchi3.jpg';
import cuchi4 from '../../assets/images/cuchi4.jpg';
import cuchi5 from '../../assets/images/cuchi5.jpg';
import cuchi6 from '../../assets/images/cuchi6.jpg';
import cuchi7 from '../../assets/images/cuchi7.jpg';
import cuchi8 from '../../assets/images/cuchi8.jpg';
import cuchi9 from '../../assets/images/cuchi9.jpg';
import cuchi10 from '../../assets/images/cuchi10.jpg';
import cuchi11 from '../../assets/images/cuchi11.jpg';
import cuchi12 from '../../assets/images/cuchi12.jpg';
import { RiAccountCircleFill } from 'react-icons/ri';
import NewRun from '../../components/NewRun/NewRun';
import { getNumber } from '../../utils/function';
import vip1icon from '../../assets/images/vip1.png';
import vip2icon from '../../assets/images/vip2.png';
import vip3icon from '../../assets/images/vip3.png';
import vip4icon from '../../assets/images/vip4.png';
import vip5icon from '../../assets/images/vip5.png';
import vip6icon from '../../assets/images/vip6.png';
import blockIcon from '../../assets/images/block.png';
import dogiadung from '../../assets/images/dogiadung.png';
import trangsuc from '../../assets/images/trangsuc.webp';
import thietbiyte from '../../assets/images/thietbiyte.jpg';
import thietbidientu from '../../assets/images/thietbidientu.jpg';
import noithat from '../../assets/images/noithat.webp';
import quanao from '../../assets/images/quanao.jpg';
import dotrangdiem from '../../assets/images/dotrangdiem.jpg';
import monan from '../../assets/images/doan.jpg';
import { FaLock } from 'react-icons/fa';

import vinhhalong from '../../assets/images/vinhhalong.jpg';
import hoian from '../../assets/images/hoian.jpg';
import saigon from '../../assets/images/saigon.jpg';
import phuquoc from '../../assets/images/phuquoc.jpg';
import dalat from '../../assets/images/dalat.jpg';
import sapa from '../../assets/images/sapa.jpg';
import Banner from './components/slider';
import { MdOutlineCreditScore } from 'react-icons/md';
import { GrLinkNext } from 'react-icons/gr';
import { GoGift } from 'react-icons/go';

const axios = require('axios').default;

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
});

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function Home(args) {
    let [user, setUser] = useState({
        data: [{ money: 0, dongbangtk: 0 }],
        moneyEarn: { moneyEarnToday: 0, moneyEarnPreDay: 0 },
    });

    const [vipLevel, setVipLevel] = useState('0');
    console.log(vipLevel);
    const { t } = useTranslation();

    const descriptionHome = [
        {
            title: 'Quần áo',
            image: quanao,
            vip: 'VIP1',
            content: '0.8%',
        },
        {
            title: 'Đồ gia dụng',
            image: dogiadung,
            vip: 'VIP2',

            content: '1.2%',
        },
        {
            title: 'Đồ trang điểm',
            vip: 'VIP3',
            image: dotrangdiem,
            content: '1.5%',
        },
        {
            title: 'Món ăn',
            vip: 'VIP4',
            image: monan,
            content: '1.8%',
        },

        {
            title: 'Thiết bị điện tử',
            vip: 'VIP5',
            image: thietbidientu,
            content: '2.5%',
        },
        {
            title: 'Nội thất Gia Dụng',
            vip: 'VIP6',
            image: noithat,
            content: '3%',
        },
        {
            title: 'Trang sức bằng vàng',
            image: trangsuc,
            vip: 'VIP7',
            content: '3.5%',
        },
        {
            title: 'Thiết bị y tế',
            vip: 'VIP8',
            image: thietbiyte,
            content: '4%',
        },
    ];

    const [listMission, setListMission] = useState([
        {
            title: 'VIP1',
            icon: vip1icon,
            rose: 1,
            content: 1,
            status: 0,
        },
        {
            title: 'VIP2',
            icon: vip2icon,
            rose: 1,
            content: 3,
            status: 0,
        },
        {
            title: 'VIP3',
            icon: vip3icon,
            rose: 1,
            content: 5,
            status: 0,
        },
        {
            title: 'VIP4',
            icon: vip4icon,
            rose: 1.5,
            content: 7,
            status: 0,
        },
        {
            title: 'VIP5',
            icon: vip5icon,
            rose: 2,
            content: 8,
            status: 0,
        },
        {
            title: 'VIP6',
            icon: vip6icon,
            rose: 2.5,
            content: 10,
            status: 0,
        },
    ]);

    // Click chuột ngoài vùng để đóng Modal
    // const modalRef = useRef();

    // useEffect(() => {
    //     function handleClickOutside(event) {
    //         if (modalRef.current && !modalRef.current.contains(event.target)) {
    //             setModalOne(false);
    //         }
    //     }

    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [modalRef]);

    useEffect(() => {
        checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;

                if (data.status === 'ok') {
                    // console.log(data);
                    setUser(data);
                    setVipLevel(data.data[0].roses_user);
                    localStorage.setItem('code', data.data[0].id_user);
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }, []);

    return (
        <div id="Home">
            <div className="p-[1.5rem] bg-[#f0f3f7] rounded-t-3xl">
                <Banner />
                <Link to="/recharge">
                    <div className="bg-[#ea943f] h-[8rem] my-8 rounded-3xl flex justify-start items-center  relative p-4">
                        <div className="text-[#ea943f] bg-white flex justify-center items-center w-[5rem] h-[5rem] rounded-full mr-4">
                            <MdOutlineCreditScore className="w-[3.5rem] h-[2.5rem]" />
                        </div>
                        <div className="flex flex-col text-white h-5rem justify-center">
                            <span className="hover:border-b w-fit text-[2.5rem]">Nạp tiền</span>
                            <span className="hover:border-b">Bắt đầu hành trình kiếm tiền của bạn bây giờ</span>
                        </div>
                        <GrLinkNext className="absolute right-4 top-[50%] translate-y-[-50%] w-[3rem] h-[2rem] text-white" />
                    </div>
                </Link>
            </div>
            <div className="flex flex-row flex-wrap  bg-[#d0cdf4] p-4 ">
                {descriptionHome.map((item, i) => {
                    return (
                        <div key={i} className="flex-1  h-[16rem] bg-white rounded-2xl min-w-[11rem] m-4 ">
                            <div className="relative">
                                <img src={item.image} alt="" className="w-full h-[12rem] rounded-t-2xl " />
                                {getNumber(vipLevel) >= i ? (
                                    <Link
                                        to="/order/mission"
                                        className="bg-[#00b4ff!important] hover:bg-[#00a2e6!important] text-white border border-white rounded-full bottom-2 left-2 absolute px-2 py-1"
                                    >
                                        Mở khóa
                                    </Link>
                                ) : (
                                    <FaLock className="text-[red] w-8 h-8 absolute bottom-2 left-2" />
                                )}
                            </div>
                            <div className="px-4 pt-2 flex justify-between items-center">
                                <div className="text-[1.5rem] font-bold text-[#007bff] hover:border-b">{item.vip}</div>

                                <div className="text-[1.4rem] text-[#b5b5b5]">{item.content}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="bg-[#f0f3f7] py-4">
                <span className="text-[1.6rem] font-medium ml-2 p-4 mt-2 store_title relative">CỬA HÀNG LIÊN KẾT</span>
                <div className="w-full p-2  flex flex-row flex-wrap">
                    <div className="min-w-[12rem] flex flex-col items-center flex-1 rounded-2xl bg-[#d0cdf4] p-2 m-2 h-[13rem]">
                        <img src={cuchi1} className="rounded-t-2xl h-[10rem] w-full" />
                        <span className="text-[1.3rem] font-medium">Siêu thị SATRAMART</span>
                    </div>
                    <div className="min-w-[12rem] flex flex-col items-center flex-1 rounded-2xl bg-[#d0cdf4] p-2 m-2 h-[13rem]">
                        <img src={cuchi2} className="rounded-t-2xl h-[10rem] w-full" />
                        <span className="text-[1.3rem] font-medium">KFC</span>
                    </div>
                    <div className="min-w-[12rem] flex flex-col items-center flex-1 rounded-2xl bg-[#d0cdf4] p-2 m-2 h-[13rem]">
                        <img src={cuchi4} className="rounded-t-2xl h-[10rem] w-full" />
                        <span className="text-[1.3rem] font-medium">Cửa hàng ALOHA</span>
                    </div>
                    <div className="min-w-[12rem] flex flex-col items-center flex-1 rounded-2xl bg-[#d0cdf4] p-2 m-2 h-[13rem]">
                        <img src={cuchi5} className="rounded-t-2xl h-[10rem] w-full" />
                        <span className="text-[1.3rem] font-medium">Trang sức PNJ</span>
                    </div>
                    <div className="min-w-[12rem] flex flex-col items-center flex-1 rounded-2xl bg-[#d0cdf4] p-2 m-2 h-[13rem]">
                        <img src={cuchi6} className="rounded-t-2xl h-[10rem] w-full" />
                        <span className="text-[1.3rem] font-medium">Hightland Coffee</span>
                    </div>
                    <div className="min-w-[12rem] flex flex-col items-center flex-1 rounded-2xl bg-[#d0cdf4] p-2 m-2 h-[13rem]">
                        <img src={cuchi7} className="rounded-t-2xl h-[10rem] w-full" />
                        <span className="text-[1.3rem] font-medium">Jollibee</span>
                    </div>
                    <div className="min-w-[12rem] flex flex-col items-center flex-1 rounded-2xl bg-[#d0cdf4] p-2 m-2 h-[13rem]">
                        <img src={cuchi8} className="rounded-t-2xl h-[10rem] w-full" />
                        <span className="text-[1.3rem] font-medium">Medicare</span>
                    </div>
                    <div className="min-w-[12rem] flex flex-col items-center flex-1 rounded-2xl bg-[#d0cdf4] p-2 m-2 h-[13rem]">
                        <img src={cuchi9} className="rounded-t-2xl h-[10rem] w-full" />
                        <span className="text-[1.3rem] font-medium">Subin BBQ</span>
                    </div>
                    <div className="min-w-[12rem] flex flex-col items-center flex-1 rounded-2xl bg-[#d0cdf4] p-2 m-2 h-[13rem]">
                        <img src={cuchi10} className="rounded-t-2xl h-[10rem] w-full" />
                        <span className="text-[1.3rem] font-medium">Rạp chiếu phim CGV</span>
                    </div>
                    <div className="min-w-[12rem] flex flex-col items-center flex-1 rounded-2xl bg-[#d0cdf4] p-2 m-2 h-[13rem]">
                        <img src={cuchi11} className="rounded-t-2xl h-[10rem] w-full" />
                        <span className="text-[1.3rem] font-medium">Miniso</span>
                    </div>
                </div>
            </div>
            <div className="p-[1.5rem] bg-[#f0f3f7] rounded-b-3xl">
                <Link to="/my/invite">
                    <div className="bg-white h-[8rem] my-8 rounded-3xl flex justify-start items-center  relative p-4">
                        <div className="text-[#ea943f] bg-[pink] flex justify-center items-center w-[5rem] h-[5rem] rounded-full mr-4">
                            <GoGift className="w-[3.5rem] h-[2.5rem] text-[#dc3545]" />
                        </div>
                        <div className="flex flex-col text-white h-5rem justify-center">
                            <span className="hover:border-b w-fit text-[2rem] text-[blue]">Mời bạn bè</span>
                            <span className="hover:border-b text-[#b5b5b5]">Kiếm hoa hồng cao</span>
                        </div>
                    </div>
                </Link>
            </div>

            {/* <div className="flex items-center justify-start mt-16">
                <RiAccountCircleFill size={48} color="#e46937" />
                <span className="ml-4 text-[#e46937] fs-1 font-semibold">
                    {t('content.home.chaoMung')} {user?.userBank?.name_u_bank}
                </span>
            </div>

            <NewRun /> */}

            {/* <div className="main-container bg-[#ffffff] mt-4" style={{ borderRadius: '25px' }}>
                <div className="content-home pb-[70px]">
                    <div className="vip-wrap px-4 py-4">
                        <div className="tab">
                            <div className="tabItem activeTabItem">Membership Level</div>
                            <div className="tabItem">Partners</div>
                        </div>
                    </div>

                    <div className="vip-list flex flex-wrap items-center justify-center">
                        {vipLevel &&
                            listMission.map((item, i) => {
                                return (
                                    <div className={`cats ${item.title === vipLevel ? 'active_cats' : ''}`} key={i}>
                                        {item.title === vipLevel ? (
                                            <Link
                                                to="/order/mission"
                                                className="central-group-img z-10 w-[90%] h-[100%] flex flex-col items-center justify-between py-4"
                                            >
                                                <img src={item.icon} alt="" width={'48px'} />
                                                <span className="fs-5 text-[#b2b2b2]">{item.title}</span>
                                                <span className="fs-3 font-bold mt-2 text-[#b2b2b2]">{item.rose}%</span>
                                            </Link>
                                        ) : (
                                            <div className="central-group-img z-10 w-[90%] h-[100%] relative flex flex-col items-center justify-between py-4">
                                                <img src={item.icon} alt="" width={'48px'} />
                                                <span className="fs-5 text-[#b2b2b2]">{item.title}</span>
                                                <span className="fs-3 font-bold mt-2 text-[#b2b2b2]">{item.rose}%</span>
                                                <img
                                                    src={blockIcon}
                                                    alt=""
                                                    className="absolute top-0 right-[-4px]"
                                                    width={12}
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                    </div>

                    <div className="flex flex-col items-center justify-center mx-4 description-home py-16">
                        {descriptionHome.map((item, i) => {
                            return (
                                <div key={i} className="flex items-center justify-center overflow-hidden py-2">
                                    <img src={item.image} alt="" width={80} height={80} />
                                    <div className="ml-4">
                                        <div className="title fs-4 font-bold">{item.title}</div>
                                        <div className="content-desc">{item.content}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div> */}

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Home;
