import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import backIcon from '../../assets/images/backicon.png';
import descriptionIcon from '../../assets/images/description.png';
import { useNavigate } from 'react-router-dom';
import { IoChevronBackOutline } from 'react-icons/io5';

function We(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    const { t } = useTranslation();

    return (
        <div className="">
            {/* <div className="header-account flex items-center justify-between">
                <div
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <img src={backIcon} alt="" width={20} />
                    <IoChevronBackOutline size={30} color="#d5582f" />
                </div>

                <div className="text-white fs-1 font-semibold">{props.title}</div>
                <div style={{ color: 'transparent' }}>11111</div>
            </div> */}

            <div className="bg-[#fff] text-2xl rounded-2xl py-4 px-4 leading-10 mt-8">
                <div className="flex items-center justify-start pb-2">
                    <img src={descriptionIcon} alt="" className="image-desc-term" />
                    <span className="ml-4 font-semibold">{t('content.we.veChungToi')}</span>
                </div>
                <hr />
                <br />
                <span>{t('content.we.thanhLap')}</span>
                <br />
                <br />
                <span>{t('content.we.sauKhi')}</span>
                <br />
                <br />
                <span>{t('content.we.vaoNam')}</span>
                <br />
                <br />
                <span>{t('content.we.muaLai')}</span>
            </div>
        </div>
    );
}

export default We;
